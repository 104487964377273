<template>
  <master-layout
    bgColor="white"
  >
    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      @click="$router.go(-1)"
    >
      <ion-fab-button class="backbutton" color="light">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-grid
      v-if="myRatings"
    >
      <ion-row class="winery-section">
        <h1 class="wineryName">Értékeléseim</h1>
      </ion-row>
      <p v-if="myRatings.length==0" class="empty-state">
        Eddig még nem értékelted egy borélményedet sem.<br/>Adj visszajelzést a borászatoknak, segítsd véleményeddel a borkedvelő közösséget és listázd észrevételeidet.
      </p>

      <ion-row
        v-for="rating in myRatings"
        :key="rating.id"
        class="winery-section"
      >
      <h3 class="wineryName">{{ rating.Winery.Name }}</h3>
        <div class="stars-date">
          <Stars
            :numberStars="rating.Stars"
          />
          <p class="mediumText">
            <HungarianDate
            :originalDate="rating.createdAt"
            />
          </p>
        </div>
        <p
          v-if="rating.Comment"
        >
          {{ rating.Comment }}
        </p>
      </ion-row>
    </ion-grid>

  </master-layout>
</template>

<script>
import axios from 'axios'
import {
  IonGrid, IonRow,
  IonFab, IonFabButton, IonIcon
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  arrowBack
} from 'ionicons/icons'
import Stars from '@/components/Stars.vue'
import HungarianDate from '@/components/HungarianDate.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CheckinConfirm',
  components: {
    IonGrid, IonRow,
    IonFab, IonFabButton, IonIcon,
    Stars, HungarianDate
  },
  data () {
    return {
      myRatings: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    })
  },
  created() {
    addIcons({
      'arrow-back': arrowBack
    })
  },
  ionViewWillEnter () {
    this.getRatings()
    window.scrollTo(0, 0)
  },
  methods: {
    async getRatings () {
      if (this.authData && this.authData.userId) {
        const myRatings = await axios.get(process.env.VUE_APP_STRAPI_URI + 'ratings?User=' + this.authData.userId + '&_sort=createdAt:DESC&_limit=400')
        this.myRatings = myRatings.data
      } else {
        this.$router.push('/profil/logged-out')
      }
    }
  }
}
</script>

<style scoped>
ion-grid{
  color: #333333;
  padding: 0px;
  clear: both;
  margin-top: calc(30px + env(safe-area-inset-top));
}
ion-row{
  --margin: 0px;
  padding: 20px 20px;
}
ion-row.rating{
  padding: 0px;
}
h3{
  font-family: Alphabet;
  font-size: 16px !important;
  width: 100%;
}
.winery-section{
  border-bottom: 1px solid #cccccc;
}
h1.wineryName{
  width: 100%;
  margin-bottom: 0;
}
.backbutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.stars-date{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.empty-state{
  margin: 30px 20px 0 20px;
  text-align: center;
}
</style>
