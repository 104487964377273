<template>
  {{ transformDate }}
</template>

<script>
export default {
  name: 'Stars',
  props: [
    'originalDate'
  ],
  computed: {
    transformDate () {
      let year = new Date(this.originalDate).getUTCFullYear()
      let month =  new Date(this.originalDate).getUTCMonth() + 1
      let day = new Date(this.originalDate).getUTCDate()
      // transform it to string
      let monthString = ""
      if (month < 10) {
        monthString = 0 + String(month)
      } else {
        monthString = String(month)
      }
      let dayString = ""
      if (day < 10) {
        dayString = 0 + String(day)
      } else {
        dayString = String(day)
      }
      const outputDate = String(year) + "." + monthString + "." + dayString
      return outputDate
    }
  }
}
</script>
