<template>
  <div>
    <div :class="'stars-container below '+ pushedClass">
      <ion-icon
        v-for="i in 5"
        :key='i'
        name="star-outline"
      />
    </div>
    <div :class="'stars-container above ' + pushedClass" :style="'width:' + averageScore">
      <ion-icon
        v-for="i in 5"
        :key='i'
        name="star"
      />
    </div>
  </div>
</template>

<script>
  import {
    IonIcon
  } from '@ionic/vue'
  import { addIcons } from 'ionicons'
  import {
    star, starOutline
  } from 'ionicons/icons'

  export default {
    name: 'Stars',
    components: {
      IonIcon
    },
    props: [
      'numberStars',
      'pushedClass'
    ],
    computed: {
      averageScore () {
        const score = this.numberStars/5
        return Math.round(score*100) + '%'
      }
    },
    created () {
      addIcons({
        'star': star,
        'star-outline': starOutline
      })
    }
  }
</script>

<style scoped>
.stars-container{
  font-size: 27px;
  padding: 0px;
  height: 27px;
}
.pushedLeft{
  margin: 0 0 0 -50%;
}
.below{
  color: var(--ion-color-dark);
}
.above{
  overflow: hidden;
  white-space: nowrap;
  margin-top: -27px;
  color: var(--ion-color-dark);
}
</style>
